import React, {useState, useEffect, useRef} from 'react';
import {View, ScrollView} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {useDebouncedCallback} from 'use-debounce';

import {textStyles} from '../../styles/text';
import OrganizationsService from '../../services/OrganizationsService';
import PageTop from '../../components/PageTop';
import AppText from '../../components/AppText';
import {
    SERVICE_TERRITORIES_FILE_PATH,
    AVATAR_DEFAULT,
    AVATAR_PATH_ORG,
    ORGANIZATION_TYPE_IDS,
    FLUID_ROLE_IDS,
    CLIENT_ACCOUNT_OWNER_ROLE_ID,
    FLUID_ADMIN_ROLE_ID
} from '../../config';
import PageContentContainer from '../../components/PageContentContainer';
import ContentSection from '../../components/ContentSection';
import Avatar from '../../components/Avatar';
import {basicStyles} from '../../styles/basic';
import ContentSectionDivider from '../../components/ContentSectionDivider';
import UploadOverlayHidden from '../../components/UploadOverlayHidden';
import TimeZonesService from '../../services/TimeZonesService';
import FieldSet from '../../components/FieldSet';
import LocationsService from '../../services/LocationsService';
import {setWarning} from '../../store/warning/warningActions';
import AppButton from '../../components/AppButton';
import {capitalizeString, formatDate} from '../../utilities/methods';
import CouponsService from '../../services/CouponsService';
import EStyleSheet from 'react-native-extended-stylesheet';
import {setProcessingIndicatorActive} from '../../store/miscellaneous/miscellaneousActions';
import ImportConfiguration from "../../components/ImportConfiguration";
import NumericInput from '../../components/NumericInput';
import ToggleField from "../../components/ToggleField";
import AppTextInput from "../../components/AppTextInput";

function OrganizationSingle(props) {
    const [organization, setOrganization] = useState({
        organization_free_trial_active: '0',
        free_trial_days: '30',
        partner_organization_id: props.auth.organization.organization_type_id == ORGANIZATION_TYPE_IDS.PARTNER ? props.auth.organization.organization_id : null,
    });
    const [organizationTypeTitle, setOrganizationTypeTitle] = useState(null);
    const [timeZones, setTimeZones] = useState([]);
    const [states, setStates] = useState([]);
    const [contactInfoChunkSize, setContactInfoChunkSize] = useState(4);
    const [accountInfoChunkSize, setAccountInfoChunkSize] = useState(2);
    const [surveyEmailChunkSize, setSurveyEmailChunkSize] = useState(2);
    const [partnerChunkSize, setPartnerChunkSize] = useState(3);
    const [coupons, setCoupons] = useState([]);
    const [serviceTerritoryImportState, setServiceTerritoryImportState] = useState({
        columns: [],
        firstRow: [],
        availableColumns: [],
        defaultConfiguration: null,
        initialized: false,
        formData: null,
    });
    const [serviceTerritoryUser, setServiceTerritoryUser] = useState('');
    const [serviceTerritoryData, setServiceTerritoryData] = useState([]);
    const {setWarning, setProcessingIndicatorActive} = props;
    const organizationId = props.route?.params?.organizationId;
    const organizationTypeId = props.route?.params?.organizationTypeId;
    const {navigate} = props.navigation;

    const firstUpdate = useRef(true);

    const updateOrganization = (prop, value) => {
        setOrganization({...organization, [prop]: value});
    };

    const [updateOrganizationDebounce] = useDebouncedCallback(() => {
        if (organizationId) {
            OrganizationsService.updateOrganization(
                organizationId,
                organization,
            );
        }
    }, 500);

    const handleImageUpload = async (results) => {
        let file = results[0];
        let formData = new FormData();
        formData.append('image', file);

        let fileName = null;
        try {
            fileName = await OrganizationsService.uploadImage(formData);
        } catch (error) {
            console.log('uploadError: ', error);
        }

        return fileName;
    };

    const updateAvatar = async (results) => {
        const fileName = await handleImageUpload(results);

        updateOrganization('organization_thumbnail', fileName);
    };

    const updateDefaultSurveyImage = async (results) => {
        const fileName = await handleImageUpload(results);

        updateOrganization('organization_default_survey_image', fileName);
    };

    const updatePluginLogo = async (results) => {
        const fileName = await handleImageUpload(results);

        updateOrganization('organization_plugin_logo_file', fileName);
    };

    const getServiceTerritoryImportConfiguration = async (results) => {
        let file = results[0];
        let formData = new FormData();
        formData.append('file', file);
        let data = null;

        try {
            data = await OrganizationsService.getServiceTerritoryImportConfiguration(organizationId, formData);
            setServiceTerritoryImportState({
                columns: data.columns,
                firstRow: data.firstRow,
                availableColumns: data.availableColumns,
                defaultConfiguration: data.defaultConfiguration,
                initialized: true,
                formData
            });
        } catch (error) {
            console.log('uploadError: ', error);
        }
    };

    const getServiceTerritoryData = async () => {
        let rows = [];
        let data = await OrganizationsService.getServiceTerritoryData(organizationId);

        if(data) {
            rows.push((
                <View key={('service-territory-item-root')} style={styles.serviceTerritoryHeader}>
                    <AppText key={('service-territory-item-root-city')} style={styles.serviceTerritoryItem}>City</AppText>
                    <AppText key={('service-territory-item-root-state')} style={styles.serviceTerritoryItem}>State</AppText>
                    <AppText key={('service-territory-item-root-zip')} style={styles.serviceTerritoryItem}>Zip Code</AppText>
                </View>
            ));

            data.forEach((e, i) => {
                if(e.google_place_zip) {
                    var row = (<View key={('service-territory-item' + i)} style={styles.serviceTerritoryRow}>
                        <AppText key={('service-territory-item-' + i + '-city')} style={[styles.serviceTerritoryItem, (i % 2 ? {backgroundColor: '#F8FAFD'} : {})]}>{e.google_place_city}</AppText>
                        <AppText key={('service-territory-item-' + i + '-state')} style={[styles.serviceTerritoryItem, (i % 2 ? {backgroundColor: '#F8FAFD'} : {})]}>{e.google_place_state}</AppText>
                        <AppText key={('service-territory-item-' + i + '-zip')} style={[styles.serviceTerritoryItem, (i % 2 ? {backgroundColor: '#F8FAFD'} : {})]}>{e.google_place_zip}</AppText>
                    </View>);
                    rows.push(row)
                }
            })
        } else {
            rows.push((
                <View>
                    <AppText>No Results</AppText>
                </View>
            ));
        }

        let results = rows.map(x => x);
        setServiceTerritoryData(results)
    };

    const importServiceTerritories = async (columnsConfiguration) => {
        let newFormData = Object.assign(serviceTerritoryImportState.formData);
        newFormData.append('columnsConfiguration', JSON.stringify(columnsConfiguration));
        try {
            setProcessingIndicatorActive(true);
            await OrganizationsService.importServiceTerritories(organizationId, newFormData);
            setProcessingIndicatorActive(false);
            setServiceTerritoryImportState(prevState => {
                return {...prevState, initialized: false}
            })
        } catch (error) {
            console.log('uploadError: ', error);
        }
    };

    const newClientFields = [
        ...(
            props.auth.permissions.includes('set_free_trial') ?
                [{
                    type: 'picker',
                    label: 'Send as Free Trial?',
                    onValueChange: value => updateOrganization('organization_free_trial_active', value),
                    selectedValue: organization.organization_free_trial_active || '',
                    items: [
                        {
                            label: 'No',
                            value: '0',
                        },
                        {
                            label: 'Yes',
                            value: '1',
                        },
                    ],
                    labelExtractor: 'label',
                    valueExtractor: 'value',
                }]
                : []
        ),
        ...(
            parseInt(organization.organization_free_trial_active) ?
                [
                    {
                        type: 'numeric',
                        label: 'Free Trial Length in Days',
                        value: organization.free_trial_days,
                        onChangeText: value => updateOrganization('free_trial_days', value),
                    },
                ]
                : []
        ),
        ...(
            coupons.length ?
                [
                    {
                        type: 'picker',
                        label: 'Coupon',
                        onValueChange: value => updateOrganization('coupon_id', value),
                        selectedValue: organization.coupon_id || '',
                        items: coupons,
                        labelExtractor: 'coupon_title',
                        valueExtractor: 'coupon_id'
                    },
                ]
                : []
        )
    ];

    const insertOrganization = async () => {
        let orgData = await OrganizationsService.insertOrganization({...organization, organization_type_id: organizationTypeId});
        if (organizationTypeId == ORGANIZATION_TYPE_IDS.CLIENT) {
            if (props.auth.organization.organization_type_id == ORGANIZATION_TYPE_IDS.FLUID) {
                navigate('ClientPartnerSingleNavigation', {
                    organizationId: orgData.organization_id,
                    screen: 'OrganizationEdit',
                });
            }
            if (props.auth.organization.organization_type_id == ORGANIZATION_TYPE_IDS.PARTNER) {
                navigate('ClientCommissions', {
                    organizationId: orgData.organization_id,
                });
            }
        } else {
            navigate('Partners', {
                screen: 'OrganizationList',
            });
        }
    };

    const warnInsertOrganization = () => {
        setWarning({
            confirmAction: () => insertOrganization(),
            confirmLabel: 'Confirm',
            rejectLabel: 'Cancel',
            warningMessage: 'Are you sure you want to create this organization?',
            active: true,
        });
    };

    const handleLayout = ({nativeEvent}) => {
        const {width} = nativeEvent.layout;

        if (width > 1065) {
            setContactInfoChunkSize(4);
            setSurveyEmailChunkSize(2);
            setPartnerChunkSize(3);
        } else if (width > 800) {
            setContactInfoChunkSize(2);
            setAccountInfoChunkSize(2);
            setSurveyEmailChunkSize(1);
            setPartnerChunkSize(2);
        } else if (width > 550) {
            setContactInfoChunkSize(2);
            setAccountInfoChunkSize(2);
            setSurveyEmailChunkSize(1);
            setPartnerChunkSize(1);
        } else {
            setContactInfoChunkSize(1);
            setAccountInfoChunkSize(1);
            setSurveyEmailChunkSize(1);
            setPartnerChunkSize(1);
        }
    };

    useEffect(() => {
        const getData = async () => {
            if (organizationId) {
                let orgData = await OrganizationsService.getOrganization(organizationId);
                setOrganization(orgData);

                //Set service territory user
                if(orgData.organization_service_territory_last_update_user_id) {
                    setServiceTerritoryUser((orgData.service_territory_profile_first_name + ' ' + orgData.service_territory_profile_last_name));
                    getServiceTerritoryData();
                }
            }
            let timeZonesData = await TimeZonesService.getTimeZones();
            setTimeZones(timeZonesData);
            let statesData = await LocationsService.getStates();
            setStates(statesData);


            firstUpdate.current = false;
        };

        getData();

        if (organizationTypeId) {
            for (const organizationType in ORGANIZATION_TYPE_IDS) {
                if (ORGANIZATION_TYPE_IDS[organizationType] == organizationTypeId) {
                    setOrganizationTypeTitle(capitalizeString(organizationType));
                }
            }
        }
    }, [organizationId, organizationTypeId]);

    useEffect(() => {
        if (firstUpdate.current) {
            return;
        }

        updateOrganizationDebounce();
    }, [organization, updateOrganizationDebounce]);

    useEffect(() => {
        if (
            props.auth.organization.organization_type_id == ORGANIZATION_TYPE_IDS.PARTNER &&
            organizationTypeId == ORGANIZATION_TYPE_IDS.CLIENT
        ) {
            const getCoupons = async () => {
                const couponsData = await CouponsService.getCoupons(props.auth.organization.organization_id);
                setCoupons(couponsData);
            };
            getCoupons();
        }
    }, [props.auth.organization.organization_type_id, props.auth.organization.organization_id, organizationTypeId]);

    return (
        <>
            <ScrollView contentContainerStyle={basicStyles.flexScale} onLayout={handleLayout}>
                <PageTop style={[
                    basicStyles.flexRow,
                    basicStyles.alignContentCenter,
                    basicStyles.justifyContentSpaceBetween,
                ]}>
                    <View style={[
                        basicStyles.flexRow,
                        basicStyles.alignContentCenter,
                        basicStyles.justifyContentSpaceBetween,
                        styles.headerContainer
                    ]}>
                        <Avatar
                            source={{uri: organization.organization_thumbnail ? AVATAR_PATH_ORG + organization.organization_thumbnail : AVATAR_DEFAULT}}
                            width={66}
                            style={{marginRight: 20}}
                        />
                        {
                            organizationId && !organizationTypeId ?
                                <AppText style={[textStyles.pageTitle, textStyles.semiBold]}>
                                    {organization.organization_title} Company Profile
                                </AppText>
                                :
                                <AppText style={[textStyles.pageTitle, textStyles.semiBold]}>
                                    New {organizationTypeTitle}
                                </AppText>
                        }
                    </View>
                </PageTop>
                <PageContentContainer>
                    {
                        !organizationId ?
                            <>
                                <View style={[
                                    basicStyles.flexRow,
                                    basicStyles.alignContentCenter,
                                    basicStyles.justifyContentSpaceBetween,
                                    {marginBottom: 25},
                                ]}>
                                    <AppText style={[textStyles.pageSubTitle, textStyles.semiBold, {marginBottom: 0}]}>
                                        Account Information
                                    </AppText>
                                    <AppButton
                                        label={'Create ' + organizationTypeTitle}
                                        action={() => warnInsertOrganization()}
                                    />
                                </View>
                                <FieldSet
                                    style={{marginBottom: 50}}
                                    chunkSize={accountInfoChunkSize}
                                    fields={[
                                        {
                                            type: 'text',
                                            label: 'Organization Title',
                                            onChangeText: value => updateOrganization('organization_title', value),
                                            value: organization.organization_title || '',
                                        },
                                        {
                                            type: 'text',
                                            label: 'Account Owner Email',
                                            onChangeText: value => updateOrganization('user_email_owner', value),
                                            value: organization.user_email_owner || '',
                                        },
                                        {
                                            type: 'text',
                                            label: 'Account Owner First Name',
                                            onChangeText: value => updateOrganization('profile_first_name_owner', value),
                                            value: organization.profile_first_name_owner || '',
                                        },
                                        {
                                            type: 'text',
                                            label: 'Account Owner Last Name',
                                            onChangeText: value => updateOrganization('profile_last_name_owner', value),
                                            value: organization.profile_last_name_owner || '',
                                        },
                                        ...(organizationTypeId == ORGANIZATION_TYPE_IDS.CLIENT ? newClientFields : []),
                                        {
                                            type: 'picker',
                                            label: 'Send Invitation?',
                                            onValueChange: value => updateOrganization('send_invitation', value),
                                            selectedValue: organization.send_invitation || '',
                                            items: [
                                                {
                                                    label: 'No',
                                                    value: '0',
                                                },
                                                {
                                                    label: 'Yes',
                                                    value: '1',
                                                },
                                            ],
                                            labelExtractor: 'label',
                                            valueExtractor: 'value',
                                        }
                                    ]}
                                />
                            </>
                            : null
                    }
                    {
                        organizationTypeId !== ORGANIZATION_TYPE_IDS.PARTNER && organization.organization_type_id != ORGANIZATION_TYPE_IDS.PARTNER ?
                            <>
                                <AppText style={[textStyles.pageSubTitle, textStyles.semiBold]}>
                                    Imagery
                                </AppText>
                                <ContentSection
                                    style={[
                                        styles.contentSection
                                    ]}>
                                    <View style={[
                                        basicStyles.padding20,
                                        basicStyles.widthHalf,
                                        basicStyles.flexScale,
                                        basicStyles.alignContentCenter,
                                        styles.contentItem
                                    ]}>
                                        <AppText style={[
                                            textStyles.semiBold,
                                            {marginBottom: 20},
                                        ]}>
                                            Company Logo
                                        </AppText>
                                        <Avatar
                                            source={{uri: organization.organization_thumbnail ? AVATAR_PATH_ORG + organization.organization_thumbnail : AVATAR_DEFAULT}}
                                            width={151}
                                            style={{marginBottom: 20}}
                                        />
                                        <View style={[
                                            basicStyles.flexRow,
                                            basicStyles.alignContentCenter,
                                        ]}>
                                            <AppButton
                                                theme="transBlue"
                                                label={organization.organization_thumbnail ? 'Change Photo' : 'Upload Photo'}
                                            >
                                                <UploadOverlayHidden
                                                    callback={updateAvatar}
                                                />
                                            </AppButton>
                                            <AppButton
                                                style={{marginLeft: 10}}
                                                theme="transRed"
                                                action={() => {
                                                    setWarning({
                                                        confirmAction: () => updateOrganization('organization_thumbnail', null),
                                                        confirmLabel: 'Remove Photo',
                                                        rejectLabel: 'Cancel',
                                                        warningMessage: 'Are you sure you want to remove the Company Logo?',
                                                        active: true,
                                                    });
                                                }}
                                                label="Remove Photo"
                                            />
                                        </View>
                                    </View>
                                    <ContentSectionDivider/>
                                    <View style={[
                                        basicStyles.padding20,
                                        basicStyles.widthHalf,
                                        basicStyles.flexScale,
                                        basicStyles.alignContentCenter,
                                        styles.contentItem
                                    ]}>
                                        <AppText style={[
                                            textStyles.semiBold,
                                            {marginBottom: 20},
                                        ]}>
                                            Default Survey Image
                                        </AppText>
                                        <Avatar
                                            source={{uri: organization.organization_default_survey_image ? AVATAR_PATH_ORG + organization.organization_default_survey_image : AVATAR_DEFAULT}}
                                            width={151}
                                            style={{marginBottom: 20}}
                                        />
                                        <View style={[
                                            basicStyles.flexRow,
                                            basicStyles.alignContentCenter,
                                        ]}>
                                            <AppButton
                                                theme="transBlue"
                                                label={organization.organization_default_survey_image ? 'Change Photo' : 'Upload Photo'}
                                            >
                                                <UploadOverlayHidden
                                                    callback={updateDefaultSurveyImage}
                                                />
                                            </AppButton>

                                            <AppButton
                                                style={{marginLeft: 10}}
                                                theme="transRed"
                                                action={() => {
                                                    setWarning({
                                                        confirmAction: () => updateOrganization('organization_default_survey_image', null),
                                                        confirmLabel: 'Remove Photo',
                                                        rejectLabel: 'Cancel',
                                                        warningMessage: 'Are you sure you want to remove the Default Survey Image?',
                                                        active: true,
                                                    });
                                                }}
                                                label="Remove Photo"
                                            />
                                        </View>
                                    </View>
                                    <ContentSectionDivider/>
                                    <View style={[
                                        basicStyles.padding20,
                                        basicStyles.widthHalf,
                                        basicStyles.flexScale,
                                        basicStyles.alignContentCenter,
                                        styles.contentItem
                                    ]}>
                                        <AppText style={[
                                            textStyles.semiBold,
                                            {marginBottom: 20},
                                        ]}>
                                            Plugin Logo
                                        </AppText>
                                        <Avatar
                                            source={{uri: organization.organization_plugin_logo_file ? AVATAR_PATH_ORG + organization.organization_plugin_logo_file : AVATAR_DEFAULT}}
                                            width={151}
                                            style={{marginBottom: 20}}
                                        />
                                        <View style={[
                                            basicStyles.flexRow,
                                            basicStyles.alignContentCenter,
                                        ]}>
                                            <AppButton
                                                theme="transBlue"
                                                label={organization.organization_plugin_logo_file ? 'Change Photo' : 'Upload Photo'}
                                            >
                                                <UploadOverlayHidden
                                                    callback={updatePluginLogo}
                                                />
                                            </AppButton>

                                            <AppButton
                                                style={{marginLeft: 10}}
                                                theme="transRed"
                                                action={() => {
                                                    setWarning({
                                                        confirmAction: () => updateOrganization('organization_plugin_logo_file', null),
                                                        confirmLabel: 'Remove Photo',
                                                        rejectLabel: 'Cancel',
                                                        warningMessage: 'Are you sure you want to remove the Plugin Logo?',
                                                        active: true,
                                                    });
                                                }}
                                                label="Remove Photo"
                                            />
                                        </View>
                                        {organization.organization_plugin_logo_file &&
                                            <View style={[
                                                basicStyles.flexRow,
                                                basicStyles.alignContentCenter,
                                            ]}>
                                                <NumericInput
                                                    label="Plugin Logo Size (px)"
                                                    onChangeText={value => updateOrganization('organization_plugin_logo_size', value)}
                                                    value={organization.organization_plugin_logo_size || ''}
                                                    wrapperStyle={{
                                                        paddingTop: 5,
                                                        paddingRight: 0,
                                                        paddingBottom: 0,
                                                        paddingLeft: 5,
                                                        height: 'auto',
                                                        marginTop: 10
                                                    }}
                                                />
                                            </View>
                                        }
                                    </View>
                                </ContentSection>
                                {(props.auth.organizationRoleId == CLIENT_ACCOUNT_OWNER_ROLE_ID
                                    || FLUID_ROLE_IDS.includes(props.auth.organizationRoleId)) && (
                                    <>
                                        <AppText style={[textStyles.pageSubTitle, textStyles.semiBold]}>
                                            Company Name
                                        </AppText>
                                        <FieldSet
                                            chunkSize={contactInfoChunkSize}
                                            style={{marginBottom: 50}}
                                            fields={[
                                                {
                                                    type: 'text',
                                                    label: 'Company Name',
                                                    onChangeText: value => updateOrganization('organization_title', value),
                                                    value: organization.organization_title || '',
                                                },
                                            ]}
                                        />
                                    </>
                                )}
                                <AppText style={[textStyles.pageSubTitle, textStyles.semiBold]}>
                                    Contact Information
                                </AppText>
                                <FieldSet
                                    chunkSize={contactInfoChunkSize}
                                    style={{marginBottom: 50}}
                                    fields={[
                                        {
                                            type: 'phone',
                                            label: 'Phone',
                                            onChangeText: value => updateOrganization('organization_phone', value),
                                            value: organization.organization_phone || '',
                                        },
                                        {
                                            type: 'text',
                                            label: 'Business Email',
                                            onChangeText: value => updateOrganization('organization_email', value),
                                            value: organization.organization_email || '',
                                        },
                                        {
                                            type: 'text',
                                            label: 'Website',
                                            onChangeText: value => updateOrganization('organization_website', value),
                                            value: organization.organization_website || '',
                                        },
                                        {
                                            type: 'picker',
                                            label: 'Time Zone',
                                            onValueChange: value => updateOrganization('time_zone_id', value),
                                            selectedValue: organization.time_zone_id || '',
                                            items: timeZones,
                                            labelExtractor: 'time_zone_title',
                                            valueExtractor: 'time_zone_id',
                                        },
                                    ]}
                                />
                                {FLUID_ROLE_IDS.includes(props.auth.organizationRoleId) && (
                                    <>
                                        <AppText style={[textStyles.pageSubTitle, textStyles.semiBold]}>
                                            Survey From Email
                                        </AppText>
                                        <FieldSet chunkSize={surveyEmailChunkSize} style={{marginBottom: 50}} fields={[
                                            {
                                                type: 'text',
                                                label: 'Survey Email Address',
                                                onChangeText: value => updateOrganization('organization_survey_email', value),
                                                value: organization.organization_survey_email || ''
                                            },
                                            {
                                                type: 'toggle',
                                                label: 'Use Survey Email Address',
                                                onChange: value => updateOrganization('organization_survey_email_active', +value),
                                                value: !!parseInt(organization.organization_survey_email_active, 10),
                                                disabled: !organization.organization_survey_email
                                            }
                                        ]} />
                                    </>
                                )}
                                <AppText style={[textStyles.pageSubTitle, textStyles.semiBold]}>
                                    Office Location
                                </AppText>
                                <FieldSet
                                    chunkSize={1}
                                    style={{marginBottom: 50}}
                                    fields={[
                                        {
                                            type: 'address',
                                            label: 'Address',
                                            onChangeAddress: value => updateOrganization('organization_address', value),
                                            value: organization.organization_address || '',
                                        },
                                        ...(FLUID_ROLE_IDS.includes(props.auth.organizationRoleId) ? [{
                                                type: 'toggle',
                                                label: 'Address listed on Google Business?',
                                                onChange: value => updateOrganization('organization_use_address_for_google_surveys', value ? '1' : '0'),
                                                value: organization.organization_use_address_for_google_surveys === '1'
                                            }] : [])
                                    ]}
                                />
                                <AppText style={[textStyles.pageSubTitle, textStyles.semiBold]}>
                                    Service Territory
                                </AppText>
                                <ContentSection style={[
                                    basicStyles.inputContainerPaddingVertical,
                                    styles.serviceTerritoryContainer
                                ]}>
                                    <View style={styles.serviceTerritoryContainerItem}>
                                        <AppText style={{marginBottom: 15}}>Upload Service Territory Zip Codes</AppText>
                                        <View style={{
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}>
                                            <View style={{alignItems: 'flex-start', flexGrow: 1}}>
                                                <AppButton
                                                    theme="transBlue"
                                                    label="Click Here"
                                                >
                                                    <UploadOverlayHidden
                                                        callback={getServiceTerritoryImportConfiguration}
                                                    />
                                                </AppButton>
                                            </View>
                                            {
                                                organization.organization_service_territory_file ?
                                                    <View style={styles.serviceTerritoryContainerItem}>
                                                        <AppButton
                                                            label="Download Current Service Territories"
                                                            action={() => window.open(SERVICE_TERRITORIES_FILE_PATH + organization.organization_service_territory_file, '_blank')}
                                                        />
                                                    </View>
                                                    : null
                                            }
                                        </View>
                                    </View>
                                    {
                                        organization.organization_service_territory_last_update_user_id ?
                                            <View style={styles.serviceTerritoryContainerItem}>
                                                <AppText>Last Update User</AppText>
                                                <AppText style={{color: 'rgb(155, 168, 202)'}}>{serviceTerritoryUser}</AppText>
                                            </View>
                                            : null
                                    }
                                    {
                                        organization.organization_service_territory_last_update ?
                                            <View style={styles.serviceTerritoryContainerItem}>
                                                <AppText>Last Update</AppText>
                                                <AppText style={{color: 'rgb(155, 168, 202)'}}>{formatDate(organization.organization_service_territory_last_update)}</AppText>
                                            </View>
                                            : null
                                    }
                                    {
                                        organization.organization_service_territory_last_update ?
                                            <View style={styles.serviceTerritoryContainerItem}>
                                                <AppButton
                                                    label="View Service Territory"
                                                    action={() => {
                                                        setWarning({
                                                            confirmLabel: 'Close',
                                                            warningMessage: (
                                                                <ScrollView contentContainerStyle={[basicStyles.flexScale, {maxHeight: 400}]} onLayout={handleLayout}>
                                                                    {serviceTerritoryData}
                                                                </ScrollView>
                                                            ),
                                                            active: true,
                                                        });
                                                    }}
                                                />
                                            </View>
                                            : null
                                    }
                                </ContentSection>
                            </>
                            :
                            <>
                                <AppText style={[textStyles.pageSubTitle, textStyles.semiBold]}>
                                    Partner
                                </AppText>
                                <FieldSet
                                    chunkSize={partnerChunkSize}
                                    fields={[
                                        {
                                            type: 'text',
                                            label: 'Organization Title',
                                            onChangeText: value => updateOrganization('organization_title', value),
                                            value: organization.organization_title || '',
                                        },
                                        {
                                            type: 'money',
                                            label: 'Partner Rate',
                                            onChangeText: value => updateOrganization('organization_partner_commission_rate', value),
                                            value: organization.organization_partner_commission_rate,
                                            unit: ''
                                        },
                                        {
                                            type: 'toggle',
                                            label: 'Display Add-On Services',
                                            onChange: value => updateOrganization('organization_display_add_ons', +value),
                                            value: !!parseInt(organization.organization_display_add_ons, 10),
                                        }
                                    ]}
                                />
                            </>
                    }

                    {props.auth.organizationRoleId == FLUID_ADMIN_ROLE_ID && (
                        <>
                            <AppText style={[textStyles.pageSubTitle, textStyles.semiBold, {marginTop: 50}]}>
                                Fluid AI Review Prompt
                            </AppText>
                            <ContentSection style={[basicStyles.inputContainerPaddingVertical]}>
                                <View style={[
                                    basicStyles.flexRow,
                                    basicStyles.alignContentCenter,
                                    basicStyles.justifyContentFlexStart,
                                    basicStyles.flexWrap,
                                    basicStyles.inputContainerPaddingHorizontal
                                ]}>
                                    <View
                                        style={[
                                            basicStyles.inputWrapper,
                                            {width: '50%'},
                                        ]}>
                                        <ToggleField
                                            label="Allow AI Response Generation"
                                            value={organization.organization_allow_ai_automatic_review_response === '1'}
                                            onChange={value => updateOrganization('organization_allow_ai_automatic_review_response', value ? '1' : '0')}
                                        />
                                    </View>
                                    <View
                                        style={[
                                            basicStyles.inputWrapper,
                                            {width: '50%'},
                                        ]}>
                                        <ToggleField
                                            label="Use Custom Prompt?"
                                            value={organization.organization_use_custom_ai_automatic_review_response_prompt === '1'}
                                            onChange={value => updateOrganization('organization_use_custom_ai_automatic_review_response_prompt', value ? '1' : '0')}
                                        />
                                    </View>
                                    <View
                                        style={[
                                            basicStyles.inputWrapper,
                                            {width: '100%'},
                                        ]}>
                                        <AppTextInput
                                            label="AI Automatic Review Response Prompt"
                                            onChangeText={value => updateOrganization('organization_ai_automatic_review_response_prompt', value)}
                                            value={organization.organization_use_custom_ai_automatic_review_response_prompt !== '1' ? organization.defaultAiReviewResponsePrompt : (organization.organization_ai_automatic_review_response_prompt || '')}
                                            multiline={true}
                                            numberOfLines={10}
                                            wrapperStyle={{height: 'auto'}}
                                            disabled={organization.organization_use_custom_ai_automatic_review_response_prompt !== '1'}
                                        />
                                    </View>
                                    <View style={{width: '100%'}}>
                                        <AppText>
                                            Available Keys for use in prompt:
                                        </AppText>
                                        <AppText>
                                            teamMemberDisplayName
                                        </AppText>
                                        <AppText>
                                            organizationName
                                        </AppText>
                                        <AppText>
                                            productBrand
                                        </AppText>
                                        <AppText>
                                            productType
                                        </AppText>
                                        <AppText>
                                            productModel
                                        </AppText>
                                        <AppText>
                                            customerName
                                        </AppText>
                                        <AppText>
                                            customerReview
                                        </AppText>
                                        <AppText>
                                            Use inside double square brackets like [[customerReview]]
                                        </AppText>
                                    </View>
                                </View>
                            </ContentSection>
                            <AppText style={[textStyles.pageSubTitle, textStyles.semiBold, {marginTop: 50}]}>
                                MessageFlow
                            </AppText>
                            <ContentSection style={[basicStyles.inputContainerPaddingVertical]}>
                                <View style={[
                                    basicStyles.flexRow,
                                    basicStyles.alignContentCenter,
                                    basicStyles.justifyContentFlexStart,
                                    basicStyles.flexWrap,
                                    basicStyles.inputContainerPaddingHorizontal
                                ]}>
                                    <View
                                        style={[
                                            basicStyles.inputWrapper,
                                            {width: '100%'}
                                        ]}>
                                        <ToggleField
                                            label="Enable MessageFlow"
                                            value={organization.organization_message_flow_active === '1'}
                                            onChange={value => updateOrganization('organization_message_flow_active', value ? '1' : '0')}
                                        />
                                    </View>
                                </View>
                            </ContentSection>
                        </>
                    )}

                </PageContentContainer>
            </ScrollView>
            {
                serviceTerritoryImportState.initialized ?
                    <ImportConfiguration
                        columns={serviceTerritoryImportState.columns}
                        firstRow={serviceTerritoryImportState.firstRow}
                        availableColumns={serviceTerritoryImportState.availableColumns}
                        defaultConfiguration={serviceTerritoryImportState.defaultConfiguration}
                        onSubmit={importServiceTerritories}
                        onOffClick={() => setServiceTerritoryImportState(prevState => {
                            return {...prevState, initialized: false}
                        })}
                    />
                    : null
            }
        </>
    );
}

const mapStateToProps = (state) => {
    const {auth} = state;
    return {auth};
};

const mapDispatchToProps = (dispatch) => (
    bindActionCreators({
        setWarning,
        setProcessingIndicatorActive
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationSingle);


const styles = EStyleSheet.create({
    contentSection: {
        flexDirection: 'row',
        marginBottom: 50
    },
    serviceTerritoryContainer: {
        paddingLeft: 30,
        paddingRight: 30,
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    serviceTerritoryContainerItem: {
        alignItems: 'flex-start',
        flexGrow: 1
    },
    serviceTerritoryHeader: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        textAlign: 'left',
        fontWeight: 'bold',
    },
    serviceTerritoryRow: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        textAlign: 'left'
    },
    serviceTerritoryItem: {
        fontSize: 15,
        width: '33.333%',
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 5,
    },
    '@media (max-width: 720)': {
        contentSection: {
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        contentItem: {
            width: '100%',
            borderBottomWidth: 1,
            borderBottomColor: '#E2E9F4'
        }
    },
    '@media (max-width: 750)': {
        headerContainer: {
            width: '100%',
        },
        serviceTerritoryContainer: {
            flexDirection: 'column',

        },
        serviceTerritoryContainerItem: {
            paddingTop: 20,
            paddingBottom: 20,
        }
    }
});
